import React, { useEffect } from "react"
import { Box, Container, Text, useColorMode } from "@chakra-ui/react"
import Layout from "../components/Layout/Layout"
import HeroSection from "../components/HeroSection"

export default function NotFoundPage() {
  return (
    <Layout title='404 - falsch abgebogen :(' colorMode='light'>
      <Box bg='brand.pearl'>
      <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ md: 8, base: 4 }}>
            <HeroSection
              alt='Band Majanko vor einer Hafenmauer'
              bgColor='brand.sand'
              justifyContent='center'
              minH={{ md: '650px', base: '400px' }}
              maxH={{ md: '650px', base: '400px' }}
              video={false}
              mediaOpacity={1}
              mediaSrc='band_wall.jpg'
              parallaxOffsetEnd={0}
              parallaxOffsetStart={0}
            >
            </HeroSection>
          </Container>

          <Container variant='layoutContainer' bg='brand.pearl' px={{ md: 8, base: 0 }}>
            <Box px={8} py={16}>
              <Text textStyle='h1' mb={8}>Sorry! diese Seite gibt es leider nicht :(</Text>
            </Box>
          </Container>
      </Box>
    </Layout>
  )
}
